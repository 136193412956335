import { Injectable } from '@angular/core';
import {
    ToteDataService,
    WagerDataService,
    JwtSessionService,
    CduxDateUtil,
} from '@cdux/ng-common';
import { IWager, WagerStatus, WagerDisplayStatus } from '@cdux/ng-fragments';
import {  Observable, of } from 'rxjs';
import { map, switchMap, tap, distinctUntilChanged, startWith, catchError } from 'rxjs/operators';
import { WagerFactoryService } from './wager.factory.service';
import { combineLatest } from 'rxjs';
import { CduxCacheService } from '@cdux/ng-platform/web';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';


@Injectable()
export class MyBetsBusinessService {
    public _betStatusesDisplayed: string[] = [
        WagerDisplayStatus.OPEN,
        WagerDisplayStatus.ACCEPTED,
    ].filter(status => status !== undefined);

    constructor(
        public sessionService: JwtSessionService,
        public toteDataService: ToteDataService,
        public cacheService: CduxCacheService,
        public wagerService: WagerDataService,
        public wagerFactory: WagerFactoryService,
        public _tournamentsSessionService: TournamentsSessionService
    ) {
        // clear local bets cache whenever the user logs out of a session
        this._setupLogoutTask();
    }

    private _setupLogoutTask(): void {
        this.sessionService.addLogoutTask(() =>
            Promise.all([
                this.flushMyBetsCache(true).catch(() => null),
                this.flushMyBetsCache(false).catch(() => null)
            ]).then(() => null),
        true);
    }

    /**
     * will fetch data fom the service
     * @returns {Observable<IWager[]>}
     */

    public fetchAndCacheBets(isTournament: boolean, showAllBets = false): Observable<IWager[]> {
        return combineLatest([
            this.wagerService.todaysWagers(true),
            this._tournamentsSessionService.isTournamentSelectedObservable()
        ]).pipe(
            map(([bets]) =>
                !bets ? [] : bets
                    .map(bet => this.wagerFactory.createActiveWager(bet))
                    .filter(bet => isTournament ? bet.wagerTypeId === 'TOURNAMENT' : bet.wagerTypeId !== 'TOURNAMENT')
                    .sort((a: IWager, b: IWager) => b.activityDate.getTime() - a.activityDate.getTime())
                    .filter(bet => showAllBets ? true : this.shouldDisplayBet(bet))
            ),
            tap(bets => this.cacheService.updateCache(this.getCacheKey(isTournament), bets)),
            catchError(error => {
                console.error('Error fetching bets:', error);
                this.cacheService.updateCache(this.getCacheKey(isTournament), []);
                return of([]);
            })
        );
    }


    public getIsTournamentSelected(): Observable<boolean> {
        return this._tournamentsSessionService.isTournamentSelectedObservable();
    }

    public getMyBetsCache(isTournament: boolean, showAllBets = false, ttl?: number): Observable<IWager[]> {
        return combineLatest([
            this.sessionService.onAuthenticationChange.pipe(startWith(this.sessionService.isLoggedIn())),
            this.toteDataService.currentRaceDate(true).pipe(distinctUntilChanged()),
            this._tournamentsSessionService.isTournamentSelectedObservable()
        ]).pipe(
            switchMap(([isLoggedIn, _]) => {
                if (!isLoggedIn) {
                    return of(<IWager[]>[]);
                }
                return this.cacheService.observeCache<IWager[]>(
                    this.getCacheKey(isTournament),
                    this.fetchAndCacheBets(isTournament),
                    isNaN(ttl) ? this.getCacheTTL() : ttl
                ).pipe(
                    map((cache: IWager[]) =>
                        !cache ? [] :  showAllBets ? cache : cache.filter(bet => this.shouldDisplayBet(bet))
                    ),
                    catchError(error => of(<IWager[]>[]))
                );
            })
        );
    }

    public async flushMyBetsCache(isTournament: boolean): Promise<IWager[]> {
        try {
            return await this.cacheService.flushCache<IWager[]>(this.getCacheKey(isTournament));
        } catch (error) {
            return [];
        }
    }

    public async refreshMyBetsCache(isTournament: boolean, showAllBets = false): Promise<IWager[]> {
        return this.fetchAndCacheBets(isTournament, showAllBets).toPromise();
    }

    public getCacheKey(isTournament: boolean): string {
        const userInfo = this.sessionService.getUserInfo();
        const prefix = isTournament ? 'cdux-mybets-tournament-' : 'cdux-mybets-primary-';
        return prefix + (userInfo && userInfo.username || 'anonymous');
    }

    public getCacheTTL(): number {
        // get number of milliseconds since midnight
        return Date.now() - CduxDateUtil.floorDate().getTime();
    }

    // Check if the given status is in the list of statuses that should be displayed
    public isDisplayableStatus(status: string | undefined): boolean {
        if (!status) {
            return false;
        }
        const isDisplayable = this._betStatusesDisplayed.some(s => {
            if (s === undefined) {
                return false;
            }
            return s.toLowerCase() === status.toLowerCase();
        });
        return isDisplayable;
    }

    public isNotCancelled(shareStatus: string): boolean {
        const lowerStatus = (shareStatus || '').toLowerCase();
        const isNotCancelled = ![WagerStatus.CANCELLED, WagerStatus.CANCELLING].includes(lowerStatus as WagerStatus);
        return isNotCancelled;
    }

    public shouldDisplayBet(bet: IWager): boolean {
        if (!bet.status) {
            return false;
        }
        const isStatusDisplayed = this._betStatusesDisplayed.includes(bet.status as WagerDisplayStatus);
        const isNotCancelled = ![WagerStatus.CANCELLED, WagerStatus.CANCELLING].includes(
            (bet?.betShareData?.betShareStatusId || '').toLowerCase() as WagerStatus
        );

        return isStatusDisplayed && isNotCancelled;
    }
}
