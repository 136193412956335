import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractMenuItemComponent } from 'app/shared/menu-items/components/abstract-menu-item.component';
import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';
import { MyBetsBusinessService } from 'app/shared/bets/services/my-bets.business.service';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';
import { map } from 'rxjs/operators';


@Component({
    selector: 'cdux-todays-bets-menu-item',
    templateUrl: './todays-bets-menu-item.component.html'
})
export class TodaysBetsMenuItemComponent extends AbstractMenuItemComponent implements OnDestroy, OnInit {
    /**
     * TODO: Provide a click event.
     *
     * @type [EventClickType}
     */
    protected clickEvent;

    /**
     * Used for determining when an external call is made to open the bets in a nav panel.
     * @type {MenuItemsEnum}
     */
    protected navTarget: MenuItemsEnum = MenuItemsEnum.TODAYS_BETS;
    public betCount: number = 0;

    private _isTournamentSelected: boolean;

    constructor(
        protected _eventTrackingService: EventTrackingService,
        protected _sidebarService: SidebarService,
        private _myBetsBusinessService: MyBetsBusinessService,
        private _tournamentSessionService: TournamentsSessionService,
    ) {
        super(_sidebarService, _eventTrackingService);
        this._isTournamentSelected = this._tournamentSessionService.isTournamentSelected();
    }

    ngOnInit(): void {
        this._subscriptions.push(
            this._myBetsBusinessService.getMyBetsCache(this._isTournamentSelected).pipe(
              map(bets =>
                bets.filter(bet =>
                  // Only include tournament bets if in tournament account, or non-tournament bets if not
                  (this._isTournamentSelected ? bet.wagerTypeId === 'TOURNAMENT' : bet.wagerTypeId !== 'TOURNAMENT')
                )
              )
            ).subscribe(activeBets => {
              this.betCount = activeBets?.length ?? 0;
            })
          );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(sub => sub.unsubscribe());
      }

    protected activate(): void {
        this._sidebarService.loadComponent(TodaysBetsContainerComponent.getSidebarComponent(), null, {
            clearHistory: true,
        });
    }

    protected deactivate(): void {
        this._sidebarService.close(true);
    }

    public formatBetCount() {
        return this.betCount > 999 ? '999+' : this.betCount;
    }
}
